<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Outlet">
                <b-row>
                    <b-col lg="4">
                        <BasicOverview v-if="!editEnabled" :data="outlet" :definitions="overviewDefinitions"/>
                        <div v-else>

                            <b-form-group>
                                <label>Name</label>
                                <b-form-input v-model="editObject.name"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Owner Name</label>
                                <b-form-input v-model="editObject.owner_name"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Contact number</label>
                                <b-form-input v-model="editObject.contact_number"/>
                            </b-form-group>

                            <b-form-group>
                                <label>TIN</label>
                                <b-form-input v-model="editObject.tin"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Segment</label>
                                <v-select
                                    v-model="editObject.segment_id"
                                    :reduce="segment => segment.id"
                                    label="name"
                                    :options="outletSegments"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Lat</label>
                                <b-form-input :value="editObject.geo_location[1]" v-on:input="updateMarkerLocationLat"/>
                            </b-form-group>

                            <b-form-group>
                                <label>Lng</label>
                                <b-form-input :value="editObject.geo_location[0]" v-on:input="updateMarkerLocationLng"/>
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col lg="8">
                        <div id="map" style="width: 100%; height: 500px"></div>
                    </b-col>
                </b-row>

                <hr/>

                <template v-if="editEnabled">
                    <b-button variant="success" @click="saveOutlet" class="mr-1">
                        <feather-icon icon="SaveIcon" class="mr-50"/>
                        <span class="align-middle">Save</span>
                    </b-button>
                    <b-button variant="danger" @click="closeEdit">
                        <feather-icon icon="XIcon" class="mr-50"/>
                        <span class="align-middle">Cancel</span>
                    </b-button>
                    <b-button v-if="outlet.status === 0 || outlet.status === 2  && $hasPermission($permissions.RemoveOutlet)" variant="danger" @click="removeOutlet" class="float-right mr-1">
                        <feather-icon icon="Trash2Icon" class="mr-50"/>
                        <span class="align-middle">Remove</span>
                    </b-button>
                   
                </template>

                <template v-else>
                    
                    <b-button v-if="$hasPermission($permissions.EditOutlets)" variant="warning" @click="openEdit" class="mr-1">
                        <feather-icon icon="EditIcon" class="mr-50"/>
                        <span class="align-middle">Edit</span>
                    </b-button>
                    
                    <b-button variant="danger" class="ml-3" v-if="outlet.status === 1 && $hasPermission($permissions.DeactivateOutlet)"
                              @click="onChangeOutletStatus(2)">
                        <feather-icon icon="XIcon" class="mr-50"/>
                        <span class="align-middle">Deactivate</span>
                    </b-button>
                    <b-button  variant="success" class="ml-3" v-else-if="outlet.status === 0 || outlet.status === 2 && $hasPermission($permissions.ApproveOutlet)"
                               @click="onChangeOutletStatus(1)">
                        <feather-icon icon="CheckIcon" class="mr-50"/>
                        <span class="align-middle">Activate</span>
                    </b-button>
                    
                </template>
            </b-card>
        </b-overlay>
        <b-row>
            <b-col lg="12">
                <OutletUserRequests :outlet-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <OutletPromotionalItems :outlet-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <OutletSales :outlet-id="$route.params.id"/>
            </b-col>
            <b-col lg="12">
                <SurveyResults :data-url="`/api/management/v1/outlet/${  $route.params.id}/survey_results`" :add-date-to-url="true"/>
            </b-col>
        </b-row>
    </div>
</template>
<script>

    import {BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow} from 'bootstrap-vue'
    import BasicOverview from '@/views/components/BasicOverview'
    import vSelect from 'vue-select'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import OutletUserRequests from '@/views/Outlet/OutletUserRequests'
    import OutletPromotionalItems from '@/views/Outlet/OutletPromotionalItems'
    import OutletSales from '@/views/Outlet/OutletSale'
    import SurveyResults from '@/views/components/SurveyResults'

    export default {
        components: {
            SurveyResults,
            OutletPromotionalItems,
            OutletUserRequests,
            BasicOverview,
            OutletSales,
            vSelect,
            BCard,
            BOverlay,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput
        },
        data() {
            return {
                dataLoaded: false,
                outlet: {
                    name: '',
                    owner_name: '',
                    contact_number: '',
                    tin: '',
                    geo_location: [
                        this.$store.state.app.default_map_view.center[0],
                        this.$store.state.app.default_map_view.center[1]
                    ],
                    segment_id: ''
                },

                map: null,
                marker: null,

                outletSegments: [],
                areas: [],

                statusMap: new Map([[0, 'Waiting approval'], [1, 'Active'], [2, 'Change needed'], [3, 'Deactivated']]),

                overviewDefinitions: [
                    { key: 'name', name: 'Name', type: 0 },
                    { key: 'status', name: 'Status', type: 0, formatFn: (data) => this.statusMap.get(data) || 'Unknown' },
                    { key: 'owner_name', name: 'Owner name', type: 0 },
                    { key: 'contact_number', name: 'Contact number', type: 0 },
                    { key: 'tin', name: 'TIN', type: 0 },
                    { key: 'segment', name: 'Segment', type: 0, formatFn: (data) => ((data.length > 0) ? data : 'Unknown') },
                    { key: 'area', name: 'Area', type: 0, formatFn: (data) => ((data.length > 0) ? data : 'Not defined') },
                    { key: 'user_full_name', name: 'User', type: 0, formatFn: (data) => ((data.length > 0) ? data : 'Unknown')},
                    { key: 'timestamp', name: 'Date created', type: 5}
                ],
                editEnabled: false,
                editObject: {}
            }
        },
        methods: {
            onChangeOutletStatus(statusID) {
                // statusID: 1 -> Active, 2 -> Deactivated

                this.dataLoaded = false
                const thisIns = this
                    
                let statusChangeURL = ''
                if (statusID === 1) statusChangeURL = 'activate'
                else if (statusID === 2) statusChangeURL = 'deactivate'
                else {
                    thisIns.$printError('There was an error updating status!')
                    return
                }

                const changeStatusPromise = this.$http.post(`/api/management/v1/outlet/${this.outlet.id}/${statusChangeURL}`)
                changeStatusPromise.then(() => {
                    thisIns.outlet.status = statusID

                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(() => {
                    this.dataLoaded = true
                })
            },
            openEdit() {
                this.editObject = JSON.parse(JSON.stringify(this.outlet))
                this.marker.setDraggable(true)
                this.editEnabled = true
            },
            closeEdit() {
                this.marker.setDraggable(false)
                this.marker.setLngLat(this.outlet.geo_location)
                this.editEnabled = false
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/outlet/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.outlet = response.data
                    thisIns.marker.setLngLat(thisIns.outlet.geo_location)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const segmentLoadPromise = this.$http.get('/api/management/v1/outlet_segment/')
                segmentLoadPromise.then(function(response) {
                    thisIns.outletSegments = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const areaLoadPromise = this.$http.get('/api/management/v1/area/')
                areaLoadPromise.then(function(response) {
                    thisIns.areas = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, segmentLoadPromise, areaLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveOutlet() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/outlet/${  this.$route.params.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Outlet saved')
                    thisIns.marker.setDraggable(false)
                    thisIns.editEnabled = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.marker = new mapboxgl.Marker({
                    draggable: false
                }).setLngLat(this.$store.state.app.default_map_view.center).addTo(this.map)

                this.marker.on('dragend', this.onMarkerDragEnd)
            },
            onMarkerDragEnd() {
                this.$set(this.editObject.geo_location, 0, this.marker.getLngLat().lng)
                this.$set(this.editObject.geo_location, 1, this.marker.getLngLat().lat)
            },
            updateMarkerLocationLat(val) {
                this.$set(this.editObject.geo_location, 1, Number(val))
                this.marker.setLngLat(this.editObject.geo_location)
            },
            updateMarkerLocationLng(val) {
                this.$set(this.editObject.geo_location, 0, Number(val))
                this.marker.setLngLat(this.editObject.geo_location)
            },
            async removeOutlet() {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this outlet?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/outlet/${  this.$route.params.id}`)
                    this.$printSuccess('Outlet removed')
                    this.$router.go(-1)
                    
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                }
            }
        },
        mounted() {
            this.attachMap()
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>